@import '../fonts/font-variables';

$f-main: $font-avenir, Georgia, sans-serif;

.font-main {
	font-family: $f-main !important;
}

body {
  font-size: 16px;
  font-family: $f-main;
  color: $c-midnight_blue;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}