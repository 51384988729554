$f-icon-version: 'yeylmu';
@font-face {
  font-family: 'icons';
  src:  url('../fonts/icons.ttf?#{$f-icon-version}') format('truetype'),
        url('../fonts/icons.woff?#{$f-icon-version}') format('woff'),
        url('../fonts/icons.svg?#{$f-icon-version}#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

$f-icons: 'icons';

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $f-icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  &.red{
    color: $c-sunset_orange;
  }

  &.yellow{
    color: #FAD73C;
  }

  &.green{
    color: $c-silver_tree;
  }

  &.blue{
    color: #4D7CFE;
  }

  &.grey{
    color: $c-logan;
  }

  &.purple{
    color: #4C4D94;
  }
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

@mixin insertIcon($icon) {
  font-family: $f-icons;
  content: unicode($icon);
}
$icons: (
ic-delete: \e95e,
ic-phone-full: \e95c,
ic-mic-on: \e95d,
ic-format: \e956,
ic-italic: \e957,
ic-unassign: \e958,
ic-underline: \e959,
ic-format-bullet: \e95a,
ic-format-list: \e95b,
ic-activity: \e900,
ic-alert-triangle: \e901,
ic-archive: \e902,
ic-arrow-down: \e903,
ic-arrow-left: \e904,
ic-arrow-right: \e905,
ic-arrow-up: \e906,
ic-assigned: \e907,
ic-at-sign: \e908,
ic-attach-a-file: \e909,
ic-attach_img: \e90a,
ic-attachment: \e90b,
ic-bar-chart-2: \e90c,
ic-book: \e90d,
ic-briefcase: \e90e,
ic-calendar: \e90f,
ic-caret-down: \e910,
ic-caret-left: \e911,
ic-caret-right: \e912,
ic-caret-up: \e913,
ic-check: \e914,
ic-chevron-down: \e915,
ic-chevron-left: \e916,
ic-chevron-right: \e917,
ic-chevron-up: \e918,
ic-clipboard: \e919,
ic-clock: \e91a,
ic-create: \e91b,
ic-create_ticket: \e91c,
ic-dashboard: \e91d,
ic-dialpad: \e91e,
ic-emoji: \e91f,
ic-file-plus: \e920,
ic-file-text: \e921,
ic-filter: \e922,
ic-folder: \e923,
ic-forward: \e924,
ic-hash: \e925,
ic-headphones: \e926,
ic-info: \e927,
ic-link: \e928,
ic-log-in: \e929,
ic-macros: \e92a,
ic-mail: \e92b,
ic-map-pin: \e92c,
ic-message-circle: \e92d,
ic-mic-off: \e92e,
ic-minus: \e92f,
ic-more-horizontal: \e930,
ic-more-vertical: \e931,
ic-notifications: \e932,
ic-notifications-off: \e933,
ic-pause: \e934,
ic-phone: \e935,
ic-phone-forwarded: \e936,
ic-phone-incoming: \e937,
ic-phone-missed: \e938,
ic-phone-off: \e939,
ic-phone-ongoing: \e93a,
ic-phone-outgoing: \e93b,
ic-pie-chart: \e93c,
ic-play: \e93d,
ic-play-circle: \e93e,
ic-plus: \e93f,
ic-plus-circle: \e940,
ic-power: \e941,
ic-printer: \e942,
ic-reply-all: \e943,
ic-reply: \e944,
ic-search: \e945,
ic-settings: \e946,
ic-shield: \e947,
ic-star: \e948,
ic-status-failure: \e949,
ic-status-success: \e94a,
ic-tag: \e94b,
ic-tickets: \e94c,
ic-transfer: \e94d,
ic-transfer-0: \e94e,
ic-trash-2: \e94f,
ic-trending-up: \e950,
ic-user: \e951,
ic-users: \e952,
ic-voicemail: \e953,
ic-x: \e954,
ic-x-circle: \e955,
);

@each $icon, $value in $icons {
  .#{$icon} {
    &:before {
      content: unicode($value);
    }
  }
}

// fixes for visual alignment
.ic-create {
  position: relative;
  top: -0.1em;
}
.ic-search {
  position: relative;
  top: -0.1em;
}

// create new by reusing old
.ic-export {
  @extend .ic-log-in;
  &:before {
    display: inline-block;
    transform: rotate(90deg);
  }
}
.ic-phone-full {
  &:before {
    display: inline-block;
    transition: transform 0.2s ease;
  }
  &__down {
    &:before {
      transform: rotate(135deg);
    }
  }
}
