@import url("https://fonts.googleapis.com/css?family=Cabin:400,500,700");
@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-Bold.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-Bold.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-Bold.svg#AvenirNext-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-BoldItalic.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-BoldItalic.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-BoldItalic.svg#AvenirNext-BoldItalic") format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-DemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-DemiBold.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-DemiBold.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-DemiBold.svg#AvenirNext-DemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-DemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-DemiBoldItalic.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-DemiBoldItalic.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-DemiBoldItalic.svg#AvenirNext-DemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-Heavy.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-Heavy.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-Heavy.svg#AvenirNext-Heavy") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-HeavyItalic.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-HeavyItalic.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-HeavyItalic.svg#AvenirNext-HeavyItalic") format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-Regular.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-Regular.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-Regular.svg#AvenirNext-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-Italic.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-Italic.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-Italic.svg#AvenirNext-Italic") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-Medium.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-Medium.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-Medium.svg#AvenirNext-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-MediumItalic.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-MediumItalic.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-MediumItalic.svg#AvenirNext-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-UltraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-UltraLight.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-UltraLight.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-UltraLight.svg#AvenirNext-UltraLight") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenir-next/AvenirNext-UltraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNext-UltraLightItalic.woff") format("woff"), url("../fonts/avenir-next/AvenirNext-UltraLightItalic.ttf") format("truetype"), url("../fonts/avenir-next/AvenirNext-UltraLightItalic.svg#AvenirNext-UltraLightItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}
