#header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background-color: $c-white;
  padding: 30px 0;
  transition: all 0.2s ease;
  border-bottom: 1px solid $c-white;

  .scrolled & {
    padding: 15px 0;
    border-bottom-color: $c-gainsboro;
  }

  .helpButtonDiv {
    margin-left: 30px;
    a {
      padding: 9px 21px;
      background-color: #67C186;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      margin-right: 0;
      margin-left: auto;
    }
  }

  .header-navbar {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
  }
  .early-access {
    transition: all 0.2s ease;
    padding: 9px 21px;
    visibility: hidden;
    opacity: 0;
    background-color: $c-han_purple;
    box-shadow: 0 3px 15px 0 rgba($c-han_purple, 0.2);
    color: $c-white;
    font-size: 16px;
    font-weight: $f-demibold;
    line-height: 22px;
    margin-right: 0;
    margin-left: auto;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .tellq-logo {
    width: 80px;
    // alligned with D of "Deliver"
    margin-left: 2.3px;
  }
  .scrolled & {
    .early-access {
      visibility: visible;
      opacity: 1;
    }
  }
  .tellqio {
    background-color: transparent;
    display: flex;
    align-items: center;
    .text {
      color: $c-midnight_blue;
      font-size: 16px;
      font-weight: $f-medium;
      line-height: 22px;
      text-align: center;
      margin-right: 13px;
    }
    svg {
      width: 15px;
      fill: $c-midnight_blue;
    }
  }
}
