.wrapper-block {
  overflow: hidden;
  position: relative;
  &.black_rock {
    background-color: $c-black_rock;
  }
  &.black_squeeze {
    background-color: $c-black_squeeze;
  }
  &.bg-graphic {
    .bg {
      position: absolute;
      top: 600px;
      left: 0;
      pointer-events: none;
      &.bg-teamwork {
        transform: rotate(90deg);
        top: -50px;
      }
    }
  }
}
.hero-block {
  padding-top: 200px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 100px;
  }
  .hero-img-wrapper {
    .hero-img {
      top: 200px;
      transform: rotate(-13deg) translateX(36%) translateY(80px);
      box-shadow: 0 18px 19px 0 rgba($c-black, 0.1);
      height: 583.33px;
      width: 1200px;
      left: 50%;
      position: absolute;
      max-width: none;
      transition: left 0.3s, opacity 0.3s;
      .beforejs & {
        left: 110%;
        opacity: 0;
      }
    }
    .hero-img-dots {
      position: absolute;
      bottom: 20px;
      left: auto;
      right: 0;
      transition: right 0.43s, opacity 0.43s;
      .beforejs & {
        right: -400px;
        opacity: 0;
      }
    }
    .hero-img-lines {
      position: absolute;
      bottom: 40px;
      left: auto;
      right: -120px;
      transition: right 0.53s, opacity 0.53s;
      .beforejs & {
        right: -600px;
        opacity: 0;
      }
    }
    @media (max-width: 1620px) {
      .hero-img {
        transform: rotate(-13deg) translateX(27%) translateY(10px);
        height: 486.11px;
        width: 1000px;
      }
    }
    @media (max-width: 1024px) {
      .hero-img {
        transform: rotate(-13deg) translateX(10%) translateY(10px);
        height: 486.11px;
        width: 1000px;
      }
    }
    @media (max-width: 768px) {
      position: relative;
      margin-bottom: 50px;
      .hero-img {
        left: 0;
        top: 0;
        position: relative;
        transform: rotate(-13deg) translateX(3%);
      }
      .hero-img-dots {
        bottom: 0;
      }
      .hero-img-lines {
        bottom: 20px;
      }
    }
  }
  .content {
    position: relative;
    max-width: 1260px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
  .heading {
    max-width: 1106px;
    font-size: 76px;
    font-weight: $f-bold;
    letter-spacing: 1.77px;
    line-height: 99px;
    margin-bottom: 33px;
    @media (max-width: 1620px) {
      max-width: 850px;
      font-size: 56px;
      line-height: 74px;
      letter-spacing: 1.31px;
      margin-bottom: 62px;
    }
    @media (max-width: 1024px) {
      font-size: 44px;
      line-height: 60px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 44px;
      letter-spacing: 0.5px;
      margin-bottom: 30px;
    }
  }
  .description {
    max-width: 553px;
    color: rgba($c-midnight_blue, 0.7);
    font-size: 26px;
    letter-spacing: 0.2px;
    line-height: 42px;
    margin-bottom: 108px;
    @media (max-width: 1620px) {
      font-size: 24px;
      line-height: 37px;
      letter-spacing: 0.18px;
      margin-bottom: 70px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.14px;
      margin-bottom: 24px;
    }
  }
  .cta-btns {
    margin-bottom: 72px;
    .btn {
      text-align: center;
    }
    @media (max-width: 1620px) {
      margin-bottom: 52px;
    }
    @media (max-width: 768px) {
      .btn {
        width: 100%;
        font-size: 18px;
        line-height: 25px;
        padding: 20px;
      }
    }
  }
  .arrow-down {
    margin-bottom: 50px;
    @media (max-width: 1620px) {
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .arrow {
    height: 34px;
    width: 34px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 11px 7px;
    &:focus {
      outline: none;
    }
    svg {
      fill: $c-midnight_blue;
      width: 20px;
      height: 12px;
    }
  }
}
