footer {
  overflow: hidden;
  position: relative;
  font-family: $font-cabin;
  padding-top: 135px;
  padding-bottom: 115px;
  @media (max-width: 768px) {
    padding-top: 15px;
    padding-bottom: 30px;
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    display: block;
    content: '';
    background-color: rgba($c-white, 0.1);
  }
  background-color: $c-black_rock;
  color: $c-white;

  .container {
    max-width: 860px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .contacts {
    .phone, .email {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      .icon {
        img {
          width: 16px;
        }
        margin-right: 15px;
      }
      .value {
        color: rgba($c-white, 0.8);
        font-size: 15px;
        font-weight: $f-medium;
        letter-spacing: 1.06px;
        line-height: 18px;
        a {
          color: inherit;
          &:hover {
            color: $c-white;
          }
        }
      }
    }
    .email {
      margin-top: 8px;
    }
  }

  .footer-columns {
    display: flex;
    align-items: flex-start;
    margin-bottom: 130px;
    flex-wrap: wrap;
    .footer-col {
      width: 33.33333%;
      @media (max-width: 768px) {
        width: 40%;
        &.contacts {
          width: 60%;
        }
      }
      @media (max-width: 380px) {
        width: 100% !important;
      }
      .heading {
        color: rgba($c-white, 0.8);
        font-size: 16px;
        font-weight: $f-bold;
        letter-spacing: 0.4px;
        line-height: 19px;
        margin-bottom: 28px;
        text-transform: uppercase;
      }
      .nav-items {
        padding: 0;
        margin: 0 0 20px 0;
        > li {
          display: block;
          list-style: none;
          margin-bottom: 12px;
        }
        a {
          height: 18px;
          width: 54px;
          color: rgba($c-white, 0.7);
          font-size: 15px;
          line-height: 18px;
          transition: all 0.2s ease;
          &:hover {
            color: $c-white;
          }
        }
      }
      .social-list {
        text-align: left;
        .social-item {
          display: inline-block;
          + .social-item {
            margin-left: 30px;
          }
          svg {
            width: 20px;
            height: 20px;
            fill: rgba($c-white, 0.5);
            transition: all 0.2s ease;
          }
          &:hover {
            svg {
              fill: $c-white;
            }
          }
        }
      }
    }
  }
  .footer-copyright {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .copyright {
    color: rgba($c-white, 0.7);
    font-size: 15px;
    line-height: 30px;
  }
  .footer-bottom {
    margin-top: 45px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .menu {
      padding: 5px 0 0 0;
      margin: 0;
      > li {
        display: inline-block;
        + li {
          margin-left: 35px;
        }
        a {
          color: rgba($c-white, 0.7);
          font-size: 15px;
          line-height: 18px;
          transition: all 0.2s ease;
          &:hover {
            color: $c-white;
          }
        }
      }
    }
    .tellq-logo {
      width: 80px;
      @media (max-width: 768px) {
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
