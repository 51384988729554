@mixin hdbtnColor($colorBg, $colorText, $borderAsBg: true) {
  background-color: $colorBg;
  color: $colorText;
  border-color: if($borderAsBg, $colorBg, $colorText);
  &:hover {
    background-color: $c-neon_blue_light;
    border-color: $c-neon_blue_light;
  }
  &:active {
    background-color: $c-neon_blue_dark;
  }
  &:disabled {
    background-color: $c-silver;
    border-color: $c-silver;
  }

}
@mixin hdbtnTransparent($colorText, $borderAsTxt: true) {
  background-color: transparent;
  color: $colorText;
  border-color: if($borderAsTxt, $colorText, transparent);
  &:hover {
    background-color: $colorText;
    color: $c-white;
  }
}
@mixin hdbtnFlat($colorText, $hoverText: false) {
  color: $colorText;
  &:hover {
    color: if($hoverText, $hoverText, darken($colorText, 10));
  }
}
@mixin hdbtnTransition() {
  transition: 0.3s ease;
}
.btn {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  //border: 2px solid transparent;
  border: none;
  font-weight: $f-demibold;
  border-radius: 4px;
  letter-spacing: 0.1px;
  position: relative;
  z-index: 1;
  &:focus {
    outline: none;
  }
  &[disabled] {
    background-color: $c-silver !important;
    box-shadow: none !important;
    cursor: initial;
  }
  &.xl {
    @include fluid-type(767px, 1200px, 12px, 14px);
    line-height: 1.75;
    padding: 0.85em 1.5em;
    min-width: 126px;
  }
  &.lg {
    //@include fluid-type(767px, 1200px, 12px, 14px);
    //padding: 0.8em 2em 0.7em 2em;
    //border-width: 1px;
    color: $c-white;
    border-radius: 4px;
    padding: 20px 43px 19px 43px;
    background-color: $c-han_purple;
    box-shadow: 0 3px 15px 0 rgba($c-han_purple, 0.2);
  }
  &.medium {
    @include fluid-type(767px, 1200px, 12px, 14px);
    padding: 0.5em 1em 0.4em 1em;
    border-width: 1px;
  }
  &.small {
    @include fluid-type(767px, 1200px, 12px, 14px);
    padding: 0.5em 0.6em 0.4em 0.6em;
    border-width: 1px;
  }

  &.han_purple {
    @include hdbtnColor($c-han_purple, $c-white);
  }
  &.transparent-logan {
    @include hdbtnTransparent($c-logan, $c-white);
  }
  &.transparent-logan2 {
    @include hdbtnTransparent($c-logan2, $c-white);
  }



  &.transparent {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }
}
