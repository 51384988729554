.helpHeader {
  background-color: #444470;
  .helpHeaderContent {
    max-width: 600px;
    margin: auto;
    padding: 50px 0px;
    padding-bottom: 50px;
    .helpHeaderContentTop {
      display: flex;
      .helpHeader-icon {
        width: 95px;
        height: 35px;
      }
      .goToTellq {
        color: white;
        font-weight: 600;
        padding-left: 390px;
        a {
          color: white;
          img {
            width: 16px;
            margin-right: 5px;
            vertical-align: top;
          }
        }
      }
    }
    .helpHeader-title {
      opacity: 0.9;
      font-weight: 400;
      padding-top: 20px;
      font-size: 25px;
      color: white;
      // font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
}

.title {
}

.help-block {
}

.helpHeader-title {
}

.help-block {
  background-color: #f3f5f7;
  .help-block-container {
    padding: 45px 0px;
    width: 600px;
    margin: auto;
    .help-block-content {
      margin: 10px 0px;
      .content-inside {
        border: 1px solid rgba(128,128,128, 0.2);
        border-radius: 5px;
        background-color: white;
        padding: 30px 20px;
        .help-block-container-icon {
          position: absolute;
          width: 90px;
          img {
            padding: 20px;
          }
        }
        .content-inside-right {
          padding-left: 110px;
          .help-block-container-title {
            font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
            line-height: 1.24;
            font-weight: 400;
            color: #444470;
            margin: -5px 0 2px;
            font-size: 18px;
          }
          .help-block-container-subheading {
            margin: 5px 0 11px;
            line-height: 1.4;
            font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px;
            color: rgba(0,0,0,0.6);
            letter-spacing: 0.1px;
          }
          .content-inside-right-author-all {
            display: flex;
            .help-block-container-authorIcon {
              img {
                border-radius: 50px;
                width: 32px;
              }
            }
            .content-inside-right-author-text {
              .help-block-container-articles {
                padding-left: 15px;
                color: #444470;
                font-size: 14px;
              }
              .help-block-container-writtenBy {
                padding-left: 15px;
                color: rgba(0,0,0,0.7);
                font-size: 13px;
                padding-top: 3px;
                font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
              }
            }
          }
        }
      }
    }
  }
}

.help-footer-container {
  background-color: #444470;
  height: 200px;
  width: 100%;
  .help-footer-inside {
    width: 100px;
    margin: auto;
    img {
      padding-top: 35px;
      color: #909aa5;
    }
  }
  .help-footer-social-links {
    display: flex;
      color: white;
      font-weight: 600;
      width: 140px;
      margin: auto;
      padding-top: 50px;
    .help-footer-social-links-fb {
      width: 25px;
    }
    .help-footer-social-links-in {
      margin-left: 95px;
      width: 25px;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}