.img-block {
  padding: 95px 0;
  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .wrapper-block.black_rock & {
    color: $c-white;
  }
  .container {
    max-width: 1580px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media #{$xs} {
      display: flex;
      align-items: center;
      .text-wrapper {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
      }
      .image-wrapper {
        margin-top: 60px;
      }
    }
    @media #{$xs_p} {
      &:not(.scroll) {
        display: flex;
        align-items: center;
      }
      &.scroll {
        height: 300vh;
        position: relative;
        .text-wrapper {
          width: auto;
          position: fixed;
          right: 50%;
          left: 15px;
          height: 100vh;
          display: flex;
          transform: translateZ(0);
          padding-top: 0;
          padding-bottom: 0;
        }
        .image {
          margin-left: 50%;
          .image-wrapper {
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    @media (max-width: 1620px) {
      max-width: 1340px;
    }
    @media (max-width: 1024px) {
      max-width: 990px;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }
  .text-wrapper {
    padding: 20px 100px;
    width: 100%;
    flex: none;
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @media #{$sm_p} {
      width: 50%;
    }
    @media (max-width: 768px) {
      padding: 20px 5px;
    }
  }
  .image {
    width: 100%;
    flex: none;
    @media #{$sm_p} {
      width: 50%;
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
  .image-wrapper {
    .image-heading {
      font-size: 24px;
      font-weight: $f-demibold;
      line-height: 33px;
      text-align: center;
      margin-top: -10px;
      margin-bottom: 10px;
      @media #{$xs} {
        margin-top: 10px;
      }
    }
    .image-description {
      color: rgba($c-midnight_blue, 0.7);
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      max-width: 298px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.imgposition {
    &-left {
      .image {
        order: 1;
      }
      .text-wrapper {
        order: 2;
      }
    }
    &-bottom {
      .container {
        display: block;
      }
      .image {
        width: 100% !important;
      }
    }
  }
  &.imgsize {
    &-half {
      @media #{$sm_p} {
        .image {
          width: 40%;
        }
        .text-wrapper {
          width: 60%;
        }
      }
    }
    &-double {
      @media #{$sm_p} {
        .image {
          width: 60%;
        }
        .text-wrapper {
          width: 40%;
        }
      }

    }
    &-full {
      .text-wrapper {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .preheading {
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 40px;
      height: 2px;
      content: '';
      display: block;
      margin-top: -1px;
      background-color: $c-midnight_blue;
      .wrapper-block.black_rock & {
        background-color: $c-white;
      }
    }
    position: relative;
    padding-left: 48px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 16px;
    margin-bottom: 8px;
    color: rgba($c-midnight_blue, 0.5);
    .wrapper-block.black_rock & {
      color: rgba($c-white, 0.5);
    }
  }
  .heading {
    font-size: 64px;
    font-weight: $f-demibold;
    line-height: 67px;
    margin-bottom: 30px;
    @media (max-width: 1620px) {
      font-size: 60px;
    }
    @media (max-width: 1024px) {
      font-size: 44px;
      line-height: 52px;
      margin-bottom: 18px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 40px;
    }
  }
  .description {
    max-width: 450px;
    font-size: 24px;
    line-height: 36px;
    color: rgba($c-midnight_blue, 0.7);
    .wrapper-block.black_rock & {
      color: rgba($c-white, 0.75);
    }
    @media (max-width: 1620px) {
      font-size: 20px;
    }

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}