.simple-block {
  padding-top: 110px;
  padding-bottom: 150px;
  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .wrapper-block.black_rock & {
    color: $c-white;
  }
  .container {
    max-width: 1580px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1620px) {
      max-width: 1340px;
    }
    @media (max-width: 1024px) {
      max-width: 990px;
    }
  }
  .heading {
    font-size: 64px;
    font-weight: $f-demibold;
    letter-spacing: 1.23px;
    line-height: 87px;
    text-align: center;
    margin-bottom: 30px;
    .try-tellq & {
      font-weight: $f-bold;
    }
    @media (max-width: 1620px) {
      font-size: 60px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }
  .subheading {
    margin-left: auto;
    margin-right: auto;
    max-width: 902px;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  .icon-items {
    margin-top: 150px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: 768px) {
      margin-top: 30px;
    }
    .icon-item {
      width: 25%;
      text-align: center;
      @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        + .icon-item {
          margin-top: 60px;
        }
      }
    }
    .icon {
      margin-bottom: 15px;
      height: 50px;
      width: 50px;
      margin-left: auto;
      margin-right: auto;
    }
    .icon-item-heading, .icon-item-description {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    .icon-item-heading {
      font-size: 20px;
      font-weight: $f-demibold;
      line-height: 27px;
      margin-bottom: 10px;
    }
    .icon-item-description {
      color: rgba($c-midnight_blue, 0.7);
      font-size: 16px;
      line-height: 22px;
    }
  }
  .cta-btns {
    margin-top: 120px;
    margin-bottom: 170px;
    text-align: center;
    @media (max-width: 768px) {
      margin-top: 40px;
      margin-bottom: 90px;
      .btn {
        width: 100%;
        font-size: 18px;
        line-height: 25px;
        padding: 20px;
      }
    }
  }
  .images {
    text-align: center;
    .image {
      margin-left: 50px;
      margin-right: 50px;
      display: inline-block;
      vertical-align: middle;
      @media (max-width: 768px) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}
