img {
    max-width: 100%;
    height: auto;
}
._bg-cover {
    background-size: cover;
    background-position: center center;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.gap-20 {
	width: 100%;
	height: 20px;
}


.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}
