.help-block-full-tellq {
  height: 100%;
  min-height: 550px;
  padding-bottom: 50px;
  .help-block-all-colection {
    width: 600px;
    margin: auto;
    padding: 15px 0px;
    font-size: 12px;
    color: black;
    span {
      opacity: 0.6;
    }
  }
  .help-block-tellq {
    margin-bottom: 40px;
    width: 600px;
    margin: auto;
    background-color: #ebeef1;
    padding-top: 10px;
    .content-inside-article {
      margin: 30px 20px;
      .help-block-container-icon-article {
        position: absolute;
          width: 90px;
          img {
            padding: 20px;
          }
      }
      .content-inside-right-article {
        padding-left: 110px;
        .help-block-container-title-article {
          font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
          line-height: 1.24;
          font-weight: 400;
          color: #444470;
          margin: -5px 0 2px;
          font-size: 18px;
        }
        .help-block-container-subheading-article {
          margin: 5px 0 11px;
          line-height: 1.4;
          font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 16px;
          color: rgba(0,0,0,0.6);
          letter-spacing: 0.1px;
        }
        .content-inside-right-author-all-article {
          display: flex;
          .help-block-container-authorIcon-article {
            img {
              border-radius: 50px;
              width: 32px;
            }
          }
          .content-inside-right-author-text-article {
            .help-block-container-articles-article {
              padding-left: 15px;
              color: #444470;
              font-size: 14px;
            }
            .help-block-container-writtenBy-article {
              padding-left: 15px;
              color: rgba(0,0,0,0.7);
              font-size: 13px;
              padding-top: 3px;
              font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
          }
        }
      }
    }
    .help-block-container-tellq {
      padding: 25px 0px;
      width: 600px;
      margin: auto;
      .help-block-content-tellq {
        margin: -1px 20px;
        .content-inside-tellq {
          border: 1px solid rgba(128,128,128, 0.2);
          background-color: white;
          padding: 30px 20px;
          .content-inside-right-tellq {
            padding-left: 10px;
            .help-block-container-title-tellq {
              font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
              line-height: 1.24;
              font-weight: 400;
              color: #444470;
              margin: -5px 0 2px;
              font-size: 18px;
            }
            .help-block-container-subheading-tellq {
              margin: 5px 0 11px;
              line-height: 1.4;
              font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
              font-size: 16px;
              color: rgba(0,0,0,0.6);
              letter-spacing: 0.1px;
            }
            .content-inside-right-author-all-tellq {
              display: flex;
              .help-block-container-authorIcon-tellq {
                img {
                  border-radius: 50px;
                  width: 32px;
                }
              }
              .content-inside-right-author-text-tellq {
                .help-block-container-date-tellq {
                  padding-top: 3px;
                  padding-left: 15px;
                  color: #444470;
                  font-size: 14px;
                }
                .help-block-container-writtenBy-tellq {
                  padding-left: 15px;
                  color: rgba(0,0,0,0.7);
                  font-size: 13px;
                  padding-top: 3px;
                  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
                }
              }
            }
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}