@import 'helpers/variables';
@import 'helpers/mixins';
@import 'shared/typography';
@import 'shared/fonts';
@import 'helpers/border-box';

@import 'layout/footer';

@import 'shared/fonts';
@import 'shared/btn';
@import 'shared/shared';

@import 'components/header';
@import 'components/block';
@import 'components/hero-block';
@import 'components/img-block';
@import 'components/simple-block';
@import 'components/help-block';
@import 'components/help-block-tellq';
@import 'components/help-article';


p, h1, h2, h3, h4, h5, h6, a {
    margin: 0;
    word-break: break-word;
    font-weight: $f-normal;
}


.block {
    &.white-smoke {
        background-color: $c-whitesmoke;
    }
}

.relative {
    position: relative;
}


.tellq-logo {
    &.white {
        .dot {
            fill: $c-white;
        }
        .adot {
            fill: $c-dark_pink;
        }
        .title {
            fill: $c-white;
        }
    }
    &.black {
        .dot {
            fill: $c-black;
        }
        .adot {
            fill: $c-dark_pink;
        }
        .title {
            fill: $c-black;
        }
    }
}

body.dialog-open {
    overflow: hidden;
}
#dialog-backdrop {
    z-index: 20;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($c-black, 0.5);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.hidden {
        display: none;
    }
}
.dialog {
    width: 100%;
    max-width: 1230px;
    box-shadow: 0 24px 30px 0 rgba($c-black, 0.1);
    position: relative;
    .dialog-bg {
        position: absolute;
        height: 100%;
        top: auto;
        right: 0;
        bottom: -180px;
        left: 0;
        pointer-events: none;
        z-index: 1;
    }
    .dialog-content {
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        overflow: hidden;
        border-radius: 24px;
        background-color: $c-white;
    }
    .dialog-close {
        user-select: none;
        position: absolute;
        top: 30px;
        right: 30px;
        width: 28px;
        height: 28px;
        cursor: pointer;
        z-index: 3;
        svg {
            path {
                fill: $c-silver;
                transition: fill 0.2s ease;
            }
        }
        &:hover {
            svg {
                path {
                    fill: $c-midnight_blue;
                }
            }
        }
    }
    .dialog-left .dialog-left-content,
    .dialog-right {
        padding: 100px 80px 60px 80px;
        @media (min-width: 768px) and (max-width: 991px) {
            padding: 50px 30px 40px 30px;
        }
    }
    .dialog-left {
        @media (max-width: 767px) {
            display: none;
        }
        width: 50%;
        flex: none;
        background-color: #F8F5FF;
        display: flex;
        flex-direction: column;
        position: relative;
        .dialog-left-content {
            position: relative;
            z-index: 2;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
        .left-heading {
            font-size: 56px;
            line-height: 74px;
            letter-spacing: 1.31px;
            font-weight: $f-bold;
            margin-bottom: 17px;
        }
        .left-subheading {
            color: rgba($c-midnight_blue, 0.7);
            font-size: 24px;
            line-height: 33px;
            letter-spacing: 0.18px;
            max-width: 465px;
        }
        .types {
            display: flex;
            align-items: flex-start;
            margin-left: -20px;
            margin-right: -20px;
            padding: 0;
            .type {
                width: 40px;
                height: 40px;
                border-radius: 4px;
                box-shadow: 0 5px 22px 0 rgba($c-black, 0.12);
                color: $c-white;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;
                margin-right: 20px;
                @media (max-width: 991px) {
                    margin-left: 15px;
                    margin-right: 15px;
                }
                svg {
                    height: 18px;
                    width: 18px;
                    path {
                        fill: $c-white;
                    }
                }
                &-inbox {
                    background-color: $c-han_purple;
                }
                &-phone {
                    background-color: $c-silver_tree;
                }
                &-sms {
                    background-color: $c-sunset_orange;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: $f-demibold;
                    letter-spacing: 0.34px;
                }
                &-chat {
                    background-color: $c-dark_tangerine;
                }
                &-messenger {
                    background-color: $c-dodger_blue;
                }
            }
        }
    }
    .dialog-right {
        @media (max-width: 767px) {
            width: 100%;
            padding: 30px 15px !important;
        }
        width: 50%;
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .dialog-form {
            width: 100%;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
        .dialog-form-heading {
            font-size: 20px;
            line-height: 27px;
            font-weight: $f-demibold;
            margin-bottom: 35px;
        }
        .checkbox-row {
            max-width: 350px;
            margin: 25px auto 40px auto;
        }
        .btn {
            display: block;
            width: 100%;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.input-row {
    padding-bottom: 25px;
    position: relative;
    width: 100%;
    input {
        width: 100%;
        padding: 8px 0;
        border: none;
        border-bottom: 1px solid $c-gainsboro;
        color: $c-midnight_blue;
        font-size: 16px;
        line-height: 24px;
        transition: border 0.2s ease;
        &::placeholder {
            color: rgba($c-midnight_blue, 0.5);
        }
        &:focus {
            outline: none;
            border-bottom-color: $c-midnight_blue;
        }
    }
    .error {
        position: absolute;
        left: 0;
        top: auto;
        right: 0;
        bottom: 5px;
        color: $c-sunset_orange;
        font-size: 12px;
        line-height: 16px;
        display: none;
    }
    &.has-error {
        input {
            border-bottom-color: $c-sunset_orange;
        }
        .error {
            display: block;
        }
    }
}
.checkbox-row {
    input[type="checkbox"] {
        display: none;
        &:checked {
            + label {
                &:after {
                    position: absolute;
                    top: 6px;
                    left: 4px;
                    height: 4px;
                    width: 11px;
                    transform: rotate(-45deg);
                    display: block;
                    content: '';
                    border-left: 2px solid $c-han_purple;
                    border-bottom: 2px solid $c-han_purple;
                }
            }
        }
        + label {
            display: block;
            user-select: none;
            position: relative;
            padding-left: 32px;
            font-size: 13px;
            line-height: 18px;
            color: rgba($c-midnight_blue, 0.6);
            letter-spacing: 0.1px;
            a {
                color: inherit;
                text-decoration: underline;
                font-size: inherit;
                transition: color 0.2s ease;
                &:hover {
                    color: $c-han_purple;
                }
            }
            &:before {
                left: 0;
                top: 0;
                display: block;
                content: '';
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: 4px;
                border: 2px solid $c-han_purple;
            }
        }
    }
}
#form-loading,
#form-error,
#form-success {
    pointer-events: none;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    padding: 30px;
    background-color: rgba($c-white, 0.8);
    &.hidden {
        opacity: 0;
    }
}
#form-loading {
    .sending {
        font-size: 28px;
        text-align: center;
        font-weight: 600;
    }
}
#form-error {
    .error {
        font-size: 24px;
        color: $c-sunset_orange;
        font-weight: 600;
    }
}
#form-success {
    background-color: $c-white;
    .success {
        font-size: 28px;
        font-weight: 600;
    }
}
.tellq-chat {
    //display: none; // UNCOMMENT LATER || LIVE CHAT
    position: fixed;
    z-index: 10;
    @media #{$xs_sm} {
      display: none !important;
    }
}
