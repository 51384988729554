@import url('https://fonts.googleapis.com/css?family=Cabin:400,500,700');
@import 'fonts/font-variables';

@mixin generateFont($local-font, $font-family, $font-root, $filename, $weight, $style) {
  font-family: $font-family;
  src: url('#{$font-root}#{$filename}.eot?#iefix') format('embedded-opentype'),
  url('#{$font-root}#{$filename}.woff') format('woff'),
  url('#{$font-root}#{$filename}.ttf')  format('truetype'),
  url('#{$font-root}#{$filename}.svg##{$local-font}') format('svg');
  font-weight: $weight;
  font-style: $style;
}
@mixin generateAvenirNextFont($filename, $weight, $style: normal) {
  $font-avenir-root: '../fonts/avenir-next/';
  $font-avenir: 'AvenirNext';
  @include generateFont($filename, $font-avenir, $font-avenir-root, $filename, $weight, $style);
}
$font-avenir: 'AvenirNext';
@font-face {
  $local-font: 'AvenirNext-Bold';
  @include generateAvenirNextFont($local-font, $f-bold, normal);
}
@font-face {
  $local-font: 'AvenirNext-BoldItalic';
  @include generateAvenirNextFont($local-font, $f-bold, italic);
}
@font-face {
  $local-font: 'AvenirNext-DemiBold';
  @include generateAvenirNextFont($local-font, $f-demibold, normal);
}
@font-face {
  $local-font: 'AvenirNext-DemiBoldItalic';
  @include generateAvenirNextFont($local-font, $f-demibold, italic);
}
@font-face {
  $local-font: 'AvenirNext-Heavy';
  @include generateAvenirNextFont($local-font, $f-heavy, normal);
}
@font-face {
  $local-font: 'AvenirNext-HeavyItalic';
  @include generateAvenirNextFont($local-font, $f-heavy, italic);
}
@font-face {
  $local-font: 'AvenirNext-Regular';
  @include generateAvenirNextFont($local-font, $f-regular, normal);
}
@font-face {
  $local-font: 'AvenirNext-Italic';
  @include generateAvenirNextFont($local-font, $f-regular, italic);
}
@font-face {
  $local-font: 'AvenirNext-Medium';
  @include generateAvenirNextFont($local-font, $f-medium, normal);
}
@font-face {
  $local-font: 'AvenirNext-MediumItalic';
  @include generateAvenirNextFont($local-font, $f-medium, italic);
}
@font-face {
  $local-font: 'AvenirNext-UltraLight';
  @include generateAvenirNextFont($local-font, $f-ultralight, normal);
}
@font-face {
  $local-font: 'AvenirNext-UltraLightItalic';
  @include generateAvenirNextFont($local-font, $f-ultralight, italic);
}
