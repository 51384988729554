/*MEDIA QUERIES*/
/*BOOTSTRAP OVERWRITES*/
.font-main {
  font-family: "AvenirNext", Georgia, sans-serif !important;
}

body {
  font-size: 16px;
  font-family: "AvenirNext", Georgia, sans-serif;
  color: #13092C;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

@font-face {
  font-family: 'icons';
  src: url("../fonts/icons.ttf?yeylmu") format("truetype"), url("../fonts/icons.woff?yeylmu") format("woff"), url("../fonts/icons.svg?yeylmu#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic.red {
  color: #FB5353;
}

.ic.yellow {
  color: #FAD73C;
}

.ic.green {
  color: #68C186;
}

.ic.blue {
  color: #4D7CFE;
}

.ic.grey {
  color: #958CAB;
}

.ic.purple {
  color: #4C4D94;
}

.ic-delete:before {
  content: "\e95e";
}

.ic-phone-full:before {
  content: "\e95c";
}

.ic-mic-on:before {
  content: "\e95d";
}

.ic-format:before {
  content: "\e956";
}

.ic-italic:before {
  content: "\e957";
}

.ic-unassign:before {
  content: "\e958";
}

.ic-underline:before {
  content: "\e959";
}

.ic-format-bullet:before {
  content: "\e95a";
}

.ic-format-list:before {
  content: "\e95b";
}

.ic-activity:before {
  content: "\e900";
}

.ic-alert-triangle:before {
  content: "\e901";
}

.ic-archive:before {
  content: "\e902";
}

.ic-arrow-down:before {
  content: "\e903";
}

.ic-arrow-left:before {
  content: "\e904";
}

.ic-arrow-right:before {
  content: "\e905";
}

.ic-arrow-up:before {
  content: "\e906";
}

.ic-assigned:before {
  content: "\e907";
}

.ic-at-sign:before {
  content: "\e908";
}

.ic-attach-a-file:before {
  content: "\e909";
}

.ic-attach_img:before {
  content: "\e90a";
}

.ic-attachment:before {
  content: "\e90b";
}

.ic-bar-chart-2:before {
  content: "\e90c";
}

.ic-book:before {
  content: "\e90d";
}

.ic-briefcase:before {
  content: "\e90e";
}

.ic-calendar:before {
  content: "\e90f";
}

.ic-caret-down:before {
  content: "\e910";
}

.ic-caret-left:before {
  content: "\e911";
}

.ic-caret-right:before {
  content: "\e912";
}

.ic-caret-up:before {
  content: "\e913";
}

.ic-check:before {
  content: "\e914";
}

.ic-chevron-down:before {
  content: "\e915";
}

.ic-chevron-left:before {
  content: "\e916";
}

.ic-chevron-right:before {
  content: "\e917";
}

.ic-chevron-up:before {
  content: "\e918";
}

.ic-clipboard:before {
  content: "\e919";
}

.ic-clock:before {
  content: "\e91a";
}

.ic-create:before {
  content: "\e91b";
}

.ic-create_ticket:before {
  content: "\e91c";
}

.ic-dashboard:before {
  content: "\e91d";
}

.ic-dialpad:before {
  content: "\e91e";
}

.ic-emoji:before {
  content: "\e91f";
}

.ic-file-plus:before {
  content: "\e920";
}

.ic-file-text:before {
  content: "\e921";
}

.ic-filter:before {
  content: "\e922";
}

.ic-folder:before {
  content: "\e923";
}

.ic-forward:before {
  content: "\e924";
}

.ic-hash:before {
  content: "\e925";
}

.ic-headphones:before {
  content: "\e926";
}

.ic-info:before {
  content: "\e927";
}

.ic-link:before {
  content: "\e928";
}

.ic-log-in:before, .ic-export:before {
  content: "\e929";
}

.ic-macros:before {
  content: "\e92a";
}

.ic-mail:before {
  content: "\e92b";
}

.ic-map-pin:before {
  content: "\e92c";
}

.ic-message-circle:before {
  content: "\e92d";
}

.ic-mic-off:before {
  content: "\e92e";
}

.ic-minus:before {
  content: "\e92f";
}

.ic-more-horizontal:before {
  content: "\e930";
}

.ic-more-vertical:before {
  content: "\e931";
}

.ic-notifications:before {
  content: "\e932";
}

.ic-notifications-off:before {
  content: "\e933";
}

.ic-pause:before {
  content: "\e934";
}

.ic-phone:before {
  content: "\e935";
}

.ic-phone-forwarded:before {
  content: "\e936";
}

.ic-phone-incoming:before {
  content: "\e937";
}

.ic-phone-missed:before {
  content: "\e938";
}

.ic-phone-off:before {
  content: "\e939";
}

.ic-phone-ongoing:before {
  content: "\e93a";
}

.ic-phone-outgoing:before {
  content: "\e93b";
}

.ic-pie-chart:before {
  content: "\e93c";
}

.ic-play:before {
  content: "\e93d";
}

.ic-play-circle:before {
  content: "\e93e";
}

.ic-plus:before {
  content: "\e93f";
}

.ic-plus-circle:before {
  content: "\e940";
}

.ic-power:before {
  content: "\e941";
}

.ic-printer:before {
  content: "\e942";
}

.ic-reply-all:before {
  content: "\e943";
}

.ic-reply:before {
  content: "\e944";
}

.ic-search:before {
  content: "\e945";
}

.ic-settings:before {
  content: "\e946";
}

.ic-shield:before {
  content: "\e947";
}

.ic-star:before {
  content: "\e948";
}

.ic-status-failure:before {
  content: "\e949";
}

.ic-status-success:before {
  content: "\e94a";
}

.ic-tag:before {
  content: "\e94b";
}

.ic-tickets:before {
  content: "\e94c";
}

.ic-transfer:before {
  content: "\e94d";
}

.ic-transfer-0:before {
  content: "\e94e";
}

.ic-trash-2:before {
  content: "\e94f";
}

.ic-trending-up:before {
  content: "\e950";
}

.ic-user:before {
  content: "\e951";
}

.ic-users:before {
  content: "\e952";
}

.ic-voicemail:before {
  content: "\e953";
}

.ic-x:before {
  content: "\e954";
}

.ic-x-circle:before {
  content: "\e955";
}

.ic-create {
  position: relative;
  top: -0.1em;
}

.ic-search {
  position: relative;
  top: -0.1em;
}

.ic-export:before {
  display: inline-block;
  transform: rotate(90deg);
}

.ic-phone-full:before {
  display: inline-block;
  transition: transform 0.2s ease;
}

.ic-phone-full__down:before {
  transform: rotate(135deg);
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

footer {
  overflow: hidden;
  position: relative;
  font-family: "Cabin";
  padding-top: 135px;
  padding-bottom: 115px;
  background-color: #242632;
  color: #fff;
}

@media (max-width: 768px) {
  footer {
    padding-top: 15px;
    padding-bottom: 30px;
  }
}

footer:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 1px;
  display: block;
  content: '';
  background-color: rgba(255, 255, 255, 0.1);
}

footer .container {
  max-width: 860px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

footer .contacts .phone, footer .contacts .email {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}

footer .contacts .phone .icon, footer .contacts .email .icon {
  margin-right: 15px;
}

footer .contacts .phone .icon img, footer .contacts .email .icon img {
  width: 16px;
}

footer .contacts .phone .value, footer .contacts .email .value {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.06px;
  line-height: 18px;
}

footer .contacts .phone .value a, footer .contacts .email .value a {
  color: inherit;
}

footer .contacts .phone .value a:hover, footer .contacts .email .value a:hover {
  color: #fff;
}

footer .contacts .email {
  margin-top: 8px;
}

footer .footer-columns {
  display: flex;
  align-items: flex-start;
  margin-bottom: 130px;
  flex-wrap: wrap;
}

footer .footer-columns .footer-col {
  width: 33.33333%;
}

@media (max-width: 768px) {
  footer .footer-columns .footer-col {
    width: 40%;
  }
  footer .footer-columns .footer-col.contacts {
    width: 60%;
  }
}

@media (max-width: 380px) {
  footer .footer-columns .footer-col {
    width: 100% !important;
  }
}

footer .footer-columns .footer-col .heading {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  line-height: 19px;
  margin-bottom: 28px;
  text-transform: uppercase;
}

footer .footer-columns .footer-col .nav-items {
  padding: 0;
  margin: 0 0 20px 0;
}

footer .footer-columns .footer-col .nav-items > li {
  display: block;
  list-style: none;
  margin-bottom: 12px;
}

footer .footer-columns .footer-col .nav-items a {
  height: 18px;
  width: 54px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  line-height: 18px;
  transition: all 0.2s ease;
}

footer .footer-columns .footer-col .nav-items a:hover {
  color: #fff;
}

footer .footer-columns .footer-col .social-list {
  text-align: left;
}

footer .footer-columns .footer-col .social-list .social-item {
  display: inline-block;
}

footer .footer-columns .footer-col .social-list .social-item + .social-item {
  margin-left: 30px;
}

footer .footer-columns .footer-col .social-list .social-item svg {
  width: 20px;
  height: 20px;
  fill: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease;
}

footer .footer-columns .footer-col .social-list .social-item:hover svg {
  fill: #fff;
}

@media (max-width: 768px) {
  footer .footer-copyright {
    display: none;
  }
}

footer .copyright {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  line-height: 30px;
}

footer .footer-bottom {
  margin-top: 45px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 768px) {
  footer .footer-bottom {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

footer .footer-bottom .menu {
  padding: 5px 0 0 0;
  margin: 0;
}

footer .footer-bottom .menu > li {
  display: inline-block;
}

footer .footer-bottom .menu > li + li {
  margin-left: 35px;
}

footer .footer-bottom .menu > li a {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  line-height: 18px;
  transition: all 0.2s ease;
}

footer .footer-bottom .menu > li a:hover {
  color: #fff;
}

footer .footer-bottom .tellq-logo {
  width: 80px;
}

@media (max-width: 768px) {
  footer .footer-bottom .tellq-logo {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}

@font-face {
  font-family: 'icons';
  src: url("../fonts/icons.ttf?yeylmu") format("truetype"), url("../fonts/icons.woff?yeylmu") format("woff"), url("../fonts/icons.svg?yeylmu#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic.red {
  color: #FB5353;
}

.ic.yellow {
  color: #FAD73C;
}

.ic.green {
  color: #68C186;
}

.ic.blue {
  color: #4D7CFE;
}

.ic.grey {
  color: #958CAB;
}

.ic.purple {
  color: #4C4D94;
}

.ic-delete:before {
  content: "\e95e";
}

.ic-phone-full:before {
  content: "\e95c";
}

.ic-mic-on:before {
  content: "\e95d";
}

.ic-format:before {
  content: "\e956";
}

.ic-italic:before {
  content: "\e957";
}

.ic-unassign:before {
  content: "\e958";
}

.ic-underline:before {
  content: "\e959";
}

.ic-format-bullet:before {
  content: "\e95a";
}

.ic-format-list:before {
  content: "\e95b";
}

.ic-activity:before {
  content: "\e900";
}

.ic-alert-triangle:before {
  content: "\e901";
}

.ic-archive:before {
  content: "\e902";
}

.ic-arrow-down:before {
  content: "\e903";
}

.ic-arrow-left:before {
  content: "\e904";
}

.ic-arrow-right:before {
  content: "\e905";
}

.ic-arrow-up:before {
  content: "\e906";
}

.ic-assigned:before {
  content: "\e907";
}

.ic-at-sign:before {
  content: "\e908";
}

.ic-attach-a-file:before {
  content: "\e909";
}

.ic-attach_img:before {
  content: "\e90a";
}

.ic-attachment:before {
  content: "\e90b";
}

.ic-bar-chart-2:before {
  content: "\e90c";
}

.ic-book:before {
  content: "\e90d";
}

.ic-briefcase:before {
  content: "\e90e";
}

.ic-calendar:before {
  content: "\e90f";
}

.ic-caret-down:before {
  content: "\e910";
}

.ic-caret-left:before {
  content: "\e911";
}

.ic-caret-right:before {
  content: "\e912";
}

.ic-caret-up:before {
  content: "\e913";
}

.ic-check:before {
  content: "\e914";
}

.ic-chevron-down:before {
  content: "\e915";
}

.ic-chevron-left:before {
  content: "\e916";
}

.ic-chevron-right:before {
  content: "\e917";
}

.ic-chevron-up:before {
  content: "\e918";
}

.ic-clipboard:before {
  content: "\e919";
}

.ic-clock:before {
  content: "\e91a";
}

.ic-create:before {
  content: "\e91b";
}

.ic-create_ticket:before {
  content: "\e91c";
}

.ic-dashboard:before {
  content: "\e91d";
}

.ic-dialpad:before {
  content: "\e91e";
}

.ic-emoji:before {
  content: "\e91f";
}

.ic-file-plus:before {
  content: "\e920";
}

.ic-file-text:before {
  content: "\e921";
}

.ic-filter:before {
  content: "\e922";
}

.ic-folder:before {
  content: "\e923";
}

.ic-forward:before {
  content: "\e924";
}

.ic-hash:before {
  content: "\e925";
}

.ic-headphones:before {
  content: "\e926";
}

.ic-info:before {
  content: "\e927";
}

.ic-link:before {
  content: "\e928";
}

.ic-log-in:before, .ic-export:before {
  content: "\e929";
}

.ic-macros:before {
  content: "\e92a";
}

.ic-mail:before {
  content: "\e92b";
}

.ic-map-pin:before {
  content: "\e92c";
}

.ic-message-circle:before {
  content: "\e92d";
}

.ic-mic-off:before {
  content: "\e92e";
}

.ic-minus:before {
  content: "\e92f";
}

.ic-more-horizontal:before {
  content: "\e930";
}

.ic-more-vertical:before {
  content: "\e931";
}

.ic-notifications:before {
  content: "\e932";
}

.ic-notifications-off:before {
  content: "\e933";
}

.ic-pause:before {
  content: "\e934";
}

.ic-phone:before {
  content: "\e935";
}

.ic-phone-forwarded:before {
  content: "\e936";
}

.ic-phone-incoming:before {
  content: "\e937";
}

.ic-phone-missed:before {
  content: "\e938";
}

.ic-phone-off:before {
  content: "\e939";
}

.ic-phone-ongoing:before {
  content: "\e93a";
}

.ic-phone-outgoing:before {
  content: "\e93b";
}

.ic-pie-chart:before {
  content: "\e93c";
}

.ic-play:before {
  content: "\e93d";
}

.ic-play-circle:before {
  content: "\e93e";
}

.ic-plus:before {
  content: "\e93f";
}

.ic-plus-circle:before {
  content: "\e940";
}

.ic-power:before {
  content: "\e941";
}

.ic-printer:before {
  content: "\e942";
}

.ic-reply-all:before {
  content: "\e943";
}

.ic-reply:before {
  content: "\e944";
}

.ic-search:before {
  content: "\e945";
}

.ic-settings:before {
  content: "\e946";
}

.ic-shield:before {
  content: "\e947";
}

.ic-star:before {
  content: "\e948";
}

.ic-status-failure:before {
  content: "\e949";
}

.ic-status-success:before {
  content: "\e94a";
}

.ic-tag:before {
  content: "\e94b";
}

.ic-tickets:before {
  content: "\e94c";
}

.ic-transfer:before {
  content: "\e94d";
}

.ic-transfer-0:before {
  content: "\e94e";
}

.ic-trash-2:before {
  content: "\e94f";
}

.ic-trending-up:before {
  content: "\e950";
}

.ic-user:before {
  content: "\e951";
}

.ic-users:before {
  content: "\e952";
}

.ic-voicemail:before {
  content: "\e953";
}

.ic-x:before {
  content: "\e954";
}

.ic-x-circle:before {
  content: "\e955";
}

.ic-create {
  position: relative;
  top: -0.1em;
}

.ic-search {
  position: relative;
  top: -0.1em;
}

.ic-export:before {
  display: inline-block;
  transform: rotate(90deg);
}

.ic-phone-full:before {
  display: inline-block;
  transition: transform 0.2s ease;
}

.ic-phone-full__down:before {
  transform: rotate(135deg);
}

.btn {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 0.1px;
  position: relative;
  z-index: 1;
}

.btn:focus {
  outline: none;
}

.btn[disabled] {
  background-color: #BCB9BA !important;
  box-shadow: none !important;
  cursor: initial;
}

.btn.xl {
  line-height: 1.75;
  padding: 0.85em 1.5em;
  min-width: 126px;
}

.btn.xl {
  font-size: 12px !important;
}

@media screen and (min-width: 767px) {
  .btn.xl {
    font-size: calc(12px + 2 * ((100vw - 767px) / 433)) !important;
  }
}

@media screen and (min-width: 1200px) {
  .btn.xl {
    font-size: 14px !important;
  }
}

.btn.lg {
  color: #fff;
  border-radius: 4px;
  padding: 20px 43px 19px 43px;
  background-color: #6322F0;
  box-shadow: 0 3px 15px 0 rgba(99, 34, 240, 0.2);
}

.btn.medium {
  padding: 0.5em 1em 0.4em 1em;
  border-width: 1px;
}

.btn.medium {
  font-size: 12px !important;
}

@media screen and (min-width: 767px) {
  .btn.medium {
    font-size: calc(12px + 2 * ((100vw - 767px) / 433)) !important;
  }
}

@media screen and (min-width: 1200px) {
  .btn.medium {
    font-size: 14px !important;
  }
}

.btn.small {
  padding: 0.5em 0.6em 0.4em 0.6em;
  border-width: 1px;
}

.btn.small {
  font-size: 12px !important;
}

@media screen and (min-width: 767px) {
  .btn.small {
    font-size: calc(12px + 2 * ((100vw - 767px) / 433)) !important;
  }
}

@media screen and (min-width: 1200px) {
  .btn.small {
    font-size: 14px !important;
  }
}

.btn.han_purple {
  background-color: #6322F0;
  color: #fff;
  border-color: #6322F0;
}

.btn.han_purple:hover {
  background-color: #8c59fa;
  border-color: #8c59fa;
}

.btn.han_purple:active {
  background-color: #6633d4;
}

.btn.han_purple:disabled {
  background-color: #BCB9BA;
  border-color: #BCB9BA;
}

.btn.transparent-logan {
  background-color: transparent;
  color: #958CAB;
  border-color: #958CAB;
}

.btn.transparent-logan:hover {
  background-color: #958CAB;
  color: #fff;
}

.btn.transparent-logan2 {
  background-color: transparent;
  color: #A7A0B5;
  border-color: #A7A0B5;
}

.btn.transparent-logan2:hover {
  background-color: #A7A0B5;
  color: #fff;
}

.btn.transparent {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

._bg-cover {
  background-size: cover;
  background-position: center center;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.gap-20 {
  width: 100%;
  height: 20px;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

#header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background-color: #fff;
  padding: 30px 0;
  transition: all 0.2s ease;
  border-bottom: 1px solid #fff;
}

.scrolled #header {
  padding: 15px 0;
  border-bottom-color: #E2E2E2;
}

#header .helpButtonDiv {
  margin-left: 30px;
}

#header .helpButtonDiv a {
  padding: 9px 21px;
  background-color: #67C186;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 0;
  margin-left: auto;
}

#header .header-navbar {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#header .right {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
}

#header .early-access {
  transition: all 0.2s ease;
  padding: 9px 21px;
  visibility: hidden;
  opacity: 0;
  background-color: #6322F0;
  box-shadow: 0 3px 15px 0 rgba(99, 34, 240, 0.2);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 0;
  margin-left: auto;
}

@media (max-width: 768px) {
  #header .early-access {
    display: none;
  }
}

#header .tellq-logo {
  width: 80px;
  margin-left: 2.3px;
}

.scrolled #header .early-access {
  visibility: visible;
  opacity: 1;
}

#header .tellqio {
  background-color: transparent;
  display: flex;
  align-items: center;
}

#header .tellqio .text {
  color: #13092C;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  margin-right: 13px;
}

#header .tellqio svg {
  width: 15px;
  fill: #13092C;
}

.wrapper-block {
  overflow: hidden;
  position: relative;
}

.wrapper-block.black_rock {
  background-color: #242632;
}

.wrapper-block.black_squeeze {
  background-color: #F9F9F8;
}

.wrapper-block.bg-graphic .bg {
  position: absolute;
  top: 600px;
  left: 0;
  pointer-events: none;
}

.wrapper-block.bg-graphic .bg.bg-teamwork {
  transform: rotate(90deg);
  top: -50px;
}

.hero-block {
  padding-top: 200px;
  position: relative;
}

@media (max-width: 768px) {
  .hero-block {
    padding-top: 100px;
  }
}

.hero-block .hero-img-wrapper .hero-img {
  top: 200px;
  transform: rotate(-13deg) translateX(36%) translateY(80px);
  box-shadow: 0 18px 19px 0 rgba(0, 0, 0, 0.1);
  height: 583.33px;
  width: 1200px;
  left: 50%;
  position: absolute;
  max-width: none;
  transition: left 0.3s, opacity 0.3s;
}

.beforejs .hero-block .hero-img-wrapper .hero-img {
  left: 110%;
  opacity: 0;
}

.hero-block .hero-img-wrapper .hero-img-dots {
  position: absolute;
  bottom: 20px;
  left: auto;
  right: 0;
  transition: right 0.43s, opacity 0.43s;
}

.beforejs .hero-block .hero-img-wrapper .hero-img-dots {
  right: -400px;
  opacity: 0;
}

.hero-block .hero-img-wrapper .hero-img-lines {
  position: absolute;
  bottom: 40px;
  left: auto;
  right: -120px;
  transition: right 0.53s, opacity 0.53s;
}

.beforejs .hero-block .hero-img-wrapper .hero-img-lines {
  right: -600px;
  opacity: 0;
}

@media (max-width: 1620px) {
  .hero-block .hero-img-wrapper .hero-img {
    transform: rotate(-13deg) translateX(27%) translateY(10px);
    height: 486.11px;
    width: 1000px;
  }
}

@media (max-width: 1024px) {
  .hero-block .hero-img-wrapper .hero-img {
    transform: rotate(-13deg) translateX(10%) translateY(10px);
    height: 486.11px;
    width: 1000px;
  }
}

@media (max-width: 768px) {
  .hero-block .hero-img-wrapper {
    position: relative;
    margin-bottom: 50px;
  }
  .hero-block .hero-img-wrapper .hero-img {
    left: 0;
    top: 0;
    position: relative;
    transform: rotate(-13deg) translateX(3%);
  }
  .hero-block .hero-img-wrapper .hero-img-dots {
    bottom: 0;
  }
  .hero-block .hero-img-wrapper .hero-img-lines {
    bottom: 20px;
  }
}

.hero-block .content {
  position: relative;
  max-width: 1260px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

.hero-block .heading {
  max-width: 1106px;
  font-size: 76px;
  font-weight: 700;
  letter-spacing: 1.77px;
  line-height: 99px;
  margin-bottom: 33px;
}

@media (max-width: 1620px) {
  .hero-block .heading {
    max-width: 850px;
    font-size: 56px;
    line-height: 74px;
    letter-spacing: 1.31px;
    margin-bottom: 62px;
  }
}

@media (max-width: 1024px) {
  .hero-block .heading {
    font-size: 44px;
    line-height: 60px;
  }
}

@media (max-width: 768px) {
  .hero-block .heading {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
  }
}

.hero-block .description {
  max-width: 553px;
  color: rgba(19, 9, 44, 0.7);
  font-size: 26px;
  letter-spacing: 0.2px;
  line-height: 42px;
  margin-bottom: 108px;
}

@media (max-width: 1620px) {
  .hero-block .description {
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0.18px;
    margin-bottom: 70px;
  }
}

@media (max-width: 768px) {
  .hero-block .description {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.14px;
    margin-bottom: 24px;
  }
}

.hero-block .cta-btns {
  margin-bottom: 72px;
}

.hero-block .cta-btns .btn {
  text-align: center;
}

@media (max-width: 1620px) {
  .hero-block .cta-btns {
    margin-bottom: 52px;
  }
}

@media (max-width: 768px) {
  .hero-block .cta-btns .btn {
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    padding: 20px;
  }
}

.hero-block .arrow-down {
  margin-bottom: 50px;
}

@media (max-width: 1620px) {
  .hero-block .arrow-down {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .hero-block .arrow-down {
    display: none;
  }
}

.hero-block .arrow {
  height: 34px;
  width: 34px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 11px 7px;
}

.hero-block .arrow:focus {
  outline: none;
}

.hero-block .arrow svg {
  fill: #13092C;
  width: 20px;
  height: 12px;
}

.img-block {
  padding: 95px 0;
}

@media (max-width: 768px) {
  .img-block {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.wrapper-block.black_rock .img-block {
  color: #fff;
}

.img-block .container {
  max-width: 1580px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .img-block .container {
    display: flex;
    align-items: center;
  }
  .img-block .container .text-wrapper {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
  }
  .img-block .container .image-wrapper {
    margin-top: 60px;
  }
}

@media (min-width: 769px) {
  .img-block .container:not(.scroll) {
    display: flex;
    align-items: center;
  }
  .img-block .container.scroll {
    height: 300vh;
    position: relative;
  }
  .img-block .container.scroll .text-wrapper {
    width: auto;
    position: fixed;
    right: 50%;
    left: 15px;
    height: 100vh;
    display: flex;
    transform: translateZ(0);
    padding-top: 0;
    padding-bottom: 0;
  }
  .img-block .container.scroll .image {
    margin-left: 50%;
  }
  .img-block .container.scroll .image .image-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1620px) {
  .img-block .container {
    max-width: 1340px;
  }
}

@media (max-width: 1024px) {
  .img-block .container {
    max-width: 990px;
  }
}

@media (max-width: 768px) {
  .img-block .container {
    display: block;
  }
}

.img-block .text-wrapper {
  padding: 20px 100px;
  width: 100%;
  flex: none;
}

.img-block .text-wrapper .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 769px) {
  .img-block .text-wrapper {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .img-block .text-wrapper {
    padding: 20px 5px;
  }
}

.img-block .image {
  width: 100%;
  flex: none;
}

@media (min-width: 769px) {
  .img-block .image {
    width: 50%;
  }
}

.img-block .image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.img-block .image-wrapper .image-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .img-block .image-wrapper .image-heading {
    margin-top: 10px;
  }
}

.img-block .image-wrapper .image-description {
  color: rgba(19, 9, 44, 0.7);
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  max-width: 298px;
  margin-left: auto;
  margin-right: auto;
}

.img-block.imgposition-left .image {
  order: 1;
}

.img-block.imgposition-left .text-wrapper {
  order: 2;
}

.img-block.imgposition-bottom .container {
  display: block;
}

.img-block.imgposition-bottom .image {
  width: 100% !important;
}

@media (min-width: 769px) {
  .img-block.imgsize-half .image {
    width: 40%;
  }
  .img-block.imgsize-half .text-wrapper {
    width: 60%;
  }
}

@media (min-width: 769px) {
  .img-block.imgsize-double .image {
    width: 60%;
  }
  .img-block.imgsize-double .text-wrapper {
    width: 40%;
  }
}

.img-block.imgsize-full .text-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.img-block .preheading {
  position: relative;
  padding-left: 48px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
  margin-bottom: 8px;
  color: rgba(19, 9, 44, 0.5);
}

.img-block .preheading:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 40px;
  height: 2px;
  content: '';
  display: block;
  margin-top: -1px;
  background-color: #13092C;
}

.wrapper-block.black_rock .img-block .preheading:before {
  background-color: #fff;
}

.wrapper-block.black_rock .img-block .preheading {
  color: rgba(255, 255, 255, 0.5);
}

.img-block .heading {
  font-size: 64px;
  font-weight: 600;
  line-height: 67px;
  margin-bottom: 30px;
}

@media (max-width: 1620px) {
  .img-block .heading {
    font-size: 60px;
  }
}

@media (max-width: 1024px) {
  .img-block .heading {
    font-size: 44px;
    line-height: 52px;
    margin-bottom: 18px;
  }
}

@media (max-width: 768px) {
  .img-block .heading {
    font-size: 36px;
    line-height: 40px;
  }
}

.img-block .description {
  max-width: 450px;
  font-size: 24px;
  line-height: 36px;
  color: rgba(19, 9, 44, 0.7);
}

.wrapper-block.black_rock .img-block .description {
  color: rgba(255, 255, 255, 0.75);
}

@media (max-width: 1620px) {
  .img-block .description {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .img-block .description {
    font-size: 18px;
    line-height: 28px;
  }
}

.simple-block {
  padding-top: 110px;
  padding-bottom: 150px;
}

@media (max-width: 768px) {
  .simple-block {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.wrapper-block.black_rock .simple-block {
  color: #fff;
}

.simple-block .container {
  max-width: 1580px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1620px) {
  .simple-block .container {
    max-width: 1340px;
  }
}

@media (max-width: 1024px) {
  .simple-block .container {
    max-width: 990px;
  }
}

.simple-block .heading {
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 1.23px;
  line-height: 87px;
  text-align: center;
  margin-bottom: 30px;
}

.try-tellq .simple-block .heading {
  font-weight: 700;
}

@media (max-width: 1620px) {
  .simple-block .heading {
    font-size: 60px;
  }
}

@media (max-width: 768px) {
  .simple-block .heading {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

.simple-block .subheading {
  margin-left: auto;
  margin-right: auto;
  max-width: 902px;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
}

@media (max-width: 768px) {
  .simple-block .subheading {
    font-size: 18px;
    line-height: 28px;
  }
}

.simple-block .icon-items {
  margin-top: 150px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 768px) {
  .simple-block .icon-items {
    margin-top: 30px;
  }
}

.simple-block .icon-items .icon-item {
  width: 25%;
  text-align: center;
}

@media (max-width: 768px) {
  .simple-block .icon-items .icon-item {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .simple-block .icon-items .icon-item + .icon-item {
    margin-top: 60px;
  }
}

.simple-block .icon-items .icon {
  margin-bottom: 15px;
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-right: auto;
}

.simple-block .icon-items .icon-item-heading, .simple-block .icon-items .icon-item-description {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.simple-block .icon-items .icon-item-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 10px;
}

.simple-block .icon-items .icon-item-description {
  color: rgba(19, 9, 44, 0.7);
  font-size: 16px;
  line-height: 22px;
}

.simple-block .cta-btns {
  margin-top: 120px;
  margin-bottom: 170px;
  text-align: center;
}

@media (max-width: 768px) {
  .simple-block .cta-btns {
    margin-top: 40px;
    margin-bottom: 90px;
  }
  .simple-block .cta-btns .btn {
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    padding: 20px;
  }
}

.simple-block .images {
  text-align: center;
}

.simple-block .images .image {
  margin-left: 50px;
  margin-right: 50px;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .simple-block .images .image {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.helpHeader {
  background-color: #444470;
}

.helpHeader .helpHeaderContent {
  max-width: 600px;
  margin: auto;
  padding: 50px 0px;
  padding-bottom: 50px;
}

.helpHeader .helpHeaderContent .helpHeaderContentTop {
  display: flex;
}

.helpHeader .helpHeaderContent .helpHeaderContentTop .helpHeader-icon {
  width: 95px;
  height: 35px;
}

.helpHeader .helpHeaderContent .helpHeaderContentTop .goToTellq {
  color: white;
  font-weight: 600;
  padding-left: 390px;
}

.helpHeader .helpHeaderContent .helpHeaderContentTop .goToTellq a {
  color: white;
}

.helpHeader .helpHeaderContent .helpHeaderContentTop .goToTellq a img {
  width: 16px;
  margin-right: 5px;
  vertical-align: top;
}

.helpHeader .helpHeaderContent .helpHeader-title {
  opacity: 0.9;
  font-weight: 400;
  padding-top: 20px;
  font-size: 25px;
  color: white;
}

.help-block {
  background-color: #f3f5f7;
}

.help-block .help-block-container {
  padding: 45px 0px;
  width: 600px;
  margin: auto;
}

.help-block .help-block-container .help-block-content {
  margin: 10px 0px;
}

.help-block .help-block-container .help-block-content .content-inside {
  border: 1px solid rgba(128, 128, 128, 0.2);
  border-radius: 5px;
  background-color: white;
  padding: 30px 20px;
}

.help-block .help-block-container .help-block-content .content-inside .help-block-container-icon {
  position: absolute;
  width: 90px;
}

.help-block .help-block-container .help-block-content .content-inside .help-block-container-icon img {
  padding: 20px;
}

.help-block .help-block-container .help-block-content .content-inside .content-inside-right {
  padding-left: 110px;
}

.help-block .help-block-container .help-block-content .content-inside .content-inside-right .help-block-container-title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.24;
  font-weight: 400;
  color: #444470;
  margin: -5px 0 2px;
  font-size: 18px;
}

.help-block .help-block-container .help-block-content .content-inside .content-inside-right .help-block-container-subheading {
  margin: 5px 0 11px;
  line-height: 1.4;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.1px;
}

.help-block .help-block-container .help-block-content .content-inside .content-inside-right .content-inside-right-author-all {
  display: flex;
}

.help-block .help-block-container .help-block-content .content-inside .content-inside-right .content-inside-right-author-all .help-block-container-authorIcon img {
  border-radius: 50px;
  width: 32px;
}

.help-block .help-block-container .help-block-content .content-inside .content-inside-right .content-inside-right-author-all .content-inside-right-author-text .help-block-container-articles {
  padding-left: 15px;
  color: #444470;
  font-size: 14px;
}

.help-block .help-block-container .help-block-content .content-inside .content-inside-right .content-inside-right-author-all .content-inside-right-author-text .help-block-container-writtenBy {
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  padding-top: 3px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.help-footer-container {
  background-color: #444470;
  height: 200px;
  width: 100%;
}

.help-footer-container .help-footer-inside {
  width: 100px;
  margin: auto;
}

.help-footer-container .help-footer-inside img {
  padding-top: 35px;
  color: #909aa5;
}

.help-footer-container .help-footer-social-links {
  display: flex;
  color: white;
  font-weight: 600;
  width: 140px;
  margin: auto;
  padding-top: 50px;
}

.help-footer-container .help-footer-social-links .help-footer-social-links-fb {
  width: 25px;
}

.help-footer-container .help-footer-social-links .help-footer-social-links-in {
  margin-left: 95px;
  width: 25px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.help-block-full-tellq {
  height: 100%;
  min-height: 550px;
  padding-bottom: 50px;
}

.help-block-full-tellq .help-block-all-colection {
  width: 600px;
  margin: auto;
  padding: 15px 0px;
  font-size: 12px;
  color: black;
}

.help-block-full-tellq .help-block-all-colection span {
  opacity: 0.6;
}

.help-block-full-tellq .help-block-tellq {
  margin-bottom: 40px;
  width: 600px;
  margin: auto;
  background-color: #ebeef1;
  padding-top: 10px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article {
  margin: 30px 20px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .help-block-container-icon-article {
  position: absolute;
  width: 90px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .help-block-container-icon-article img {
  padding: 20px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .content-inside-right-article {
  padding-left: 110px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .content-inside-right-article .help-block-container-title-article {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.24;
  font-weight: 400;
  color: #444470;
  margin: -5px 0 2px;
  font-size: 18px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .content-inside-right-article .help-block-container-subheading-article {
  margin: 5px 0 11px;
  line-height: 1.4;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.1px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .content-inside-right-article .content-inside-right-author-all-article {
  display: flex;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .content-inside-right-article .content-inside-right-author-all-article .help-block-container-authorIcon-article img {
  border-radius: 50px;
  width: 32px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .content-inside-right-article .content-inside-right-author-all-article .content-inside-right-author-text-article .help-block-container-articles-article {
  padding-left: 15px;
  color: #444470;
  font-size: 14px;
}

.help-block-full-tellq .help-block-tellq .content-inside-article .content-inside-right-article .content-inside-right-author-all-article .content-inside-right-author-text-article .help-block-container-writtenBy-article {
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  padding-top: 3px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq {
  padding: 25px 0px;
  width: 600px;
  margin: auto;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq {
  margin: -1px 20px;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq .content-inside-tellq {
  border: 1px solid rgba(128, 128, 128, 0.2);
  background-color: white;
  padding: 30px 20px;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq .content-inside-tellq .content-inside-right-tellq {
  padding-left: 10px;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq .content-inside-tellq .content-inside-right-tellq .help-block-container-title-tellq {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.24;
  font-weight: 400;
  color: #444470;
  margin: -5px 0 2px;
  font-size: 18px;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq .content-inside-tellq .content-inside-right-tellq .help-block-container-subheading-tellq {
  margin: 5px 0 11px;
  line-height: 1.4;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.1px;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq .content-inside-tellq .content-inside-right-tellq .content-inside-right-author-all-tellq {
  display: flex;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq .content-inside-tellq .content-inside-right-tellq .content-inside-right-author-all-tellq .help-block-container-authorIcon-tellq img {
  border-radius: 50px;
  width: 32px;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq .content-inside-tellq .content-inside-right-tellq .content-inside-right-author-all-tellq .content-inside-right-author-text-tellq .help-block-container-date-tellq {
  padding-top: 3px;
  padding-left: 15px;
  color: #444470;
  font-size: 14px;
}

.help-block-full-tellq .help-block-tellq .help-block-container-tellq .help-block-content-tellq .content-inside-tellq .content-inside-right-tellq .content-inside-right-author-all-tellq .content-inside-right-author-text-tellq .help-block-container-writtenBy-tellq {
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  padding-top: 3px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.article-full {
  height: 100%;
  min-height: 550px;
  padding-bottom: 50px;
}

.article-full .article-all-colection {
  width: 600px;
  margin: auto;
  padding: 15px 0px;
  font-size: 12px;
  color: black;
}

.article-full .article-all-colection span {
  opacity: 0.6;
}

.article-full .article-content {
  margin-bottom: 50px;
  width: 600px;
  margin: auto;
  padding: 55px;
  border: 1px solid grey;
  background: white;
}

.article-full .article-content .article-content-upper .article-name-full .article-title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.24;
  font-weight: 400;
  color: #444470;
  margin: -5px 0 2px;
  font-size: 18px;
}

.article-full .article-content .article-content-upper .article-name-full .article-subtitle {
  margin: 5px 0 11px;
  line-height: 1.4;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.1px;
}

.article-full .article-content .article-content-upper .article-name-full .article-about {
  display: flex;
}

.article-full .article-content .article-content-upper .article-name-full .article-about .written-updated .written-by {
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  padding-top: 3px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.article-full .article-content .article-content-upper .article-name-full .article-about .written-updated .when-updated {
  padding-left: 15px;
  color: #444470;
  font-size: 14px;
  padding-top: 5px;
}

.article-full .article-content .article-content-upper .article-name-full .article-about .icon {
  border-radius: 50px;
  width: 32px;
  height: 32px;
}

.article-full .article-content .article-content-upper .video-content {
  padding-top: 40px;
}

.article-full .article-content .article-content-upper .video-content .content-name {
  margin: 5px 0 20px;
  line-height: 1.4;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.1px;
}

.article-full .article-content .article-content-upper .video-content .content-between {
  padding: 25px 0px;
}

.article-full .article-content .article-content-upper .question .name {
  font-size: 17px;
  color: #444470;
  font-weight: 600;
  padding: 40px 0px 20px 0px;
}

.article-full .article-content .article-content-upper .question p {
  font-size: 17px;
  color: #565867;
  line-height: 1.40;
  padding: 10px 0px;
}

.article-full .article-content .article-content-upper .question .images {
  padding: 20px 0px;
}

.article-full .article-content .article-content-upper .question .content-between {
  padding-bottom: 20px;
}

.article-full .article-content .article-content-upper .buttons .button {
  background-color: #444470;
  width: fit-content;
  border: 1px solid #444470;
  border-radius: 4px;
  height: 40px;
  margin: 15px 0px;
}

.article-full .article-content .article-content-upper .buttons .button .title {
  color: white;
  padding: 10px 20px;
  display: flex;
  font-weight: 500;
}

.article-full .article-content .article-content-upper .buttons .update {
  padding: 15px 0px;
  color: #444470;
}

.article-full .article-content .helpfull {
  width: 600px;
  margin-left: -56px;
  height: 100px;
  background-color: rgba(138, 138, 138, 0.2);
  margin-bottom: -56px;
}

.article-full .article-content .helpfull .title {
  font-family: inherit;
  text-align: center;
  padding: 10px;
  line-height: 1.4;
  font-size: 16px;
}

.article-full .article-content .helpfull .emoji {
  width: 150px;
  margin: auto;
  display: flex;
  height: 50px;
}

.article-full .article-content .helpfull .emoji .face {
  height: 50px;
  width: 50px;
}

.article-full .article-content .helpfull .emoji .face .Bad, .article-full .article-content .helpfull .emoji .face .Good, .article-full .article-content .helpfull .emoji .face .Great {
  height: 30px;
  width: 30px;
  margin: auto;
}

.article-full .article-content .helpfull .emoji .face .Bad:hover, .article-full .article-content .helpfull .emoji .face .Good:hover, .article-full .article-content .helpfull .emoji .face .Great:hover {
  height: 30px;
  width: 40px;
}

p, h1, h2, h3, h4, h5, h6, a {
  margin: 0;
  word-break: break-word;
  font-weight: 400;
}

.block.white-smoke {
  background-color: #F1F0F0;
}

.relative {
  position: relative;
}

.tellq-logo.white .dot {
  fill: #fff;
}

.tellq-logo.white .adot {
  fill: #E65D99;
}

.tellq-logo.white .title {
  fill: #fff;
}

.tellq-logo.black .dot {
  fill: #000;
}

.tellq-logo.black .adot {
  fill: #E65D99;
}

.tellq-logo.black .title {
  fill: #000;
}

body.dialog-open {
  overflow: hidden;
}

#dialog-backdrop {
  z-index: 20;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#dialog-backdrop.hidden {
  display: none;
}

.dialog {
  width: 100%;
  max-width: 1230px;
  box-shadow: 0 24px 30px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.dialog .dialog-bg {
  position: absolute;
  height: 100%;
  top: auto;
  right: 0;
  bottom: -180px;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.dialog .dialog-content {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  border-radius: 24px;
  background-color: #fff;
}

.dialog .dialog-close {
  user-select: none;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 3;
}

.dialog .dialog-close svg path {
  fill: #BCB9BA;
  transition: fill 0.2s ease;
}

.dialog .dialog-close:hover svg path {
  fill: #13092C;
}

.dialog .dialog-left .dialog-left-content,
.dialog .dialog-right {
  padding: 100px 80px 60px 80px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .dialog .dialog-left .dialog-left-content,
  .dialog .dialog-right {
    padding: 50px 30px 40px 30px;
  }
}

.dialog .dialog-left {
  width: 50%;
  flex: none;
  background-color: #F8F5FF;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (max-width: 767px) {
  .dialog .dialog-left {
    display: none;
  }
}

.dialog .dialog-left .dialog-left-content {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.dialog .dialog-left .left-heading {
  font-size: 56px;
  line-height: 74px;
  letter-spacing: 1.31px;
  font-weight: 700;
  margin-bottom: 17px;
}

.dialog .dialog-left .left-subheading {
  color: rgba(19, 9, 44, 0.7);
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.18px;
  max-width: 465px;
}

.dialog .dialog-left .types {
  display: flex;
  align-items: flex-start;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0;
}

.dialog .dialog-left .types .type {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 991px) {
  .dialog .dialog-left .types .type {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.dialog .dialog-left .types .type svg {
  height: 18px;
  width: 18px;
}

.dialog .dialog-left .types .type svg path {
  fill: #fff;
}

.dialog .dialog-left .types .type-inbox {
  background-color: #6322F0;
}

.dialog .dialog-left .types .type-phone {
  background-color: #68C186;
}

.dialog .dialog-left .types .type-sms {
  background-color: #FB5353;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.34px;
}

.dialog .dialog-left .types .type-chat {
  background-color: #FFA90F;
}

.dialog .dialog-left .types .type-messenger {
  background-color: #0084FF;
}

.dialog .dialog-right {
  width: 50%;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media (max-width: 767px) {
  .dialog .dialog-right {
    width: 100%;
    padding: 30px 15px !important;
  }
}

.dialog .dialog-right .dialog-form {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.dialog .dialog-right .dialog-form-heading {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 35px;
}

.dialog .dialog-right .checkbox-row {
  max-width: 350px;
  margin: 25px auto 40px auto;
}

.dialog .dialog-right .btn {
  display: block;
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.input-row {
  padding-bottom: 25px;
  position: relative;
  width: 100%;
}

.input-row input {
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #E2E2E2;
  color: #13092C;
  font-size: 16px;
  line-height: 24px;
  transition: border 0.2s ease;
}

.input-row input::placeholder {
  color: rgba(19, 9, 44, 0.5);
}

.input-row input:focus {
  outline: none;
  border-bottom-color: #13092C;
}

.input-row .error {
  position: absolute;
  left: 0;
  top: auto;
  right: 0;
  bottom: 5px;
  color: #FB5353;
  font-size: 12px;
  line-height: 16px;
  display: none;
}

.input-row.has-error input {
  border-bottom-color: #FB5353;
}

.input-row.has-error .error {
  display: block;
}

.checkbox-row input[type="checkbox"] {
  display: none;
}

.checkbox-row input[type="checkbox"]:checked + label:after {
  position: absolute;
  top: 6px;
  left: 4px;
  height: 4px;
  width: 11px;
  transform: rotate(-45deg);
  display: block;
  content: '';
  border-left: 2px solid #6322F0;
  border-bottom: 2px solid #6322F0;
}

.checkbox-row input[type="checkbox"] + label {
  display: block;
  user-select: none;
  position: relative;
  padding-left: 32px;
  font-size: 13px;
  line-height: 18px;
  color: rgba(19, 9, 44, 0.6);
  letter-spacing: 0.1px;
}

.checkbox-row input[type="checkbox"] + label a {
  color: inherit;
  text-decoration: underline;
  font-size: inherit;
  transition: color 0.2s ease;
}

.checkbox-row input[type="checkbox"] + label a:hover {
  color: #6322F0;
}

.checkbox-row input[type="checkbox"] + label:before {
  left: 0;
  top: 0;
  display: block;
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid #6322F0;
}

#form-loading,
#form-error,
#form-success {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8);
}

#form-loading.hidden,
#form-error.hidden,
#form-success.hidden {
  opacity: 0;
}

#form-loading .sending {
  font-size: 28px;
  text-align: center;
  font-weight: 600;
}

#form-error .error {
  font-size: 24px;
  color: #FB5353;
  font-weight: 600;
}

#form-success {
  background-color: #fff;
}

#form-success .success {
  font-size: 28px;
  font-weight: 600;
}

.tellq-chat {
  position: fixed;
  z-index: 10;
}

@media (max-width: 991px) {
  .tellq-chat {
    display: none !important;
  }
}
