/*MEDIA QUERIES*/

$xxs: "(max-width: 379px)";
$xs: "(max-width: 768px)";
$xs_p: "(min-width: 769px)";
$xs_sm: "(max-width: 991px)";
$sm: "(min-width: 769px) and (max-width: 991px)";
$sm_p: "(min-width: 769px)";
$sm_md: "(min-width: 769px) and (max-width: 1199px)";
$md: "(min-width: 992px) and (max-width: 1199px)";
$md_p: "(min-width: 992px)";
$lg: "(min-width: 1200px) and (max-width: 1599px)";
$lg_p: "(min-width: 1200px)";
$lg_m: "(max-width: 1199px)";
$xlg: "(min-width: 1600px)";
$xlg_m: "(max-width: 1599px)";

/*BOOTSTRAP OVERWRITES*/

//$grid-columns : 12;
//$grid-gutter-width: 30px;
//
//$grid-breakpoints: ( // Extra small screen / phone
//xs: 0, // Small screen / phone
//sm: 576px, // Medium screen / tablet
//md: 768px, // Large screen / desktop
//lg: 992px, // Extra large screen / wide desktop
//xl: 1200px);
//
//$container-max-widths: (
//sm: 540px,
//md: 720px,
//lg: 960px,
//xl: 1600px);

// using http://www.color-blindness.com/color-name-hue/
$c-white: #fff;
$c-black: #000;
$c-neon_blue: #783CFA;
$c-neon_blue2: #4D7CFE;
$c-silver_tree: #68C186;
$c-logan: #958CAB;
$c-logan2: #A7A0B5;
$c-selago: #F9F7FA;
$c-gainsboro: #E2E2E2;
$c-gainsboro2: #D8D8D8;
$c-sunset_orange: #FB5353;
$c-midnight_blue: #13092C;
$c-silver: #BCB9BA;
$c-whitesmoke: #F1F0F0;
$c-crusta: #F98752;
$c-spun_pearl: #A19DAB;
$c-sunrise_yellow: #FAD646;
$c-barbie_pink: #DB43FA;
$c-citrus_lime: #4DE895;
$c-lightning_blue: #4CD5FF;
$c-han_purple: #6322F0;
$c-jon: #40363A;
$c-black_rock: #242632;
$c-black_squeeze: #F9F9F8;
$c-dark_pink: #E65D99;
$c-dark_tangerine: #FFA90F;
$c-dodger_blue: #0084FF;

$c-neon_blue_dark: #6633d4;
$c-neon_blue_light: #8c59fa;
$c-neon_blue_super_light: #E9DFFE;

$c-light_coral: #F78B8B;
$c-fringy_flower: #B6E0C4;
$c-rose_bud: #FAB08E;
$c-light_slate_blue: #AB87FA;
$c-santas_grey: #9B9CAB;
$c-maya_blue: #80A2FF;
$c-danube: #6D83C2;
$c-oxley: #72987F;
$c-portica: #F2E550;

// $c-neon_b_white_overlay: #FFFFFF26;
// $c-neon_b_black_overlay: #00000026;
// ----
$c-placeholder-bg: rgba($c-logan, 0.3);
$c-placeholder-bdrs: 9px;

// Checkbox
$checkbox-height: 22px;
$duration: .2s;
