.article-full {
  height: 100%;
  min-height: 550px;
  padding-bottom: 50px;
  .article-all-colection {
    width: 600px;
    margin: auto;
    padding: 15px 0px;
    font-size: 12px;
    color: black;
    span {
      opacity: 0.6;
    }
    }
  .article-content {
    margin-bottom: 50px;
    width: 600px;
    margin: auto;
    padding: 55px;
    border: 1px solid grey;
    background: white;
    .article-content-upper {
      .article-name-full {

        .article-title {
          font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
          line-height: 1.24;
          font-weight: 400;
          color: #444470;
          margin: -5px 0 2px;
          font-size: 18px;
        }
        .article-subtitle {
          margin: 5px 0 11px;
          line-height: 1.4;
          font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 16px;
          color: rgba(0,0,0,0.6);
          letter-spacing: 0.1px;
        }
        .article-about {
          display: flex;
          .written-updated {
            .written-by {
              padding-left: 15px;
              color: rgba(0,0,0,0.7);
              font-size: 13px;
              padding-top: 3px;
              font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
            .when-updated {
              padding-left: 15px;
              color: #444470;
              font-size: 14px;
              padding-top: 5px;
            }
          }
          .icon {
            border-radius: 50px;
            width: 32px;
            height: 32px;
          }
        }
      }
      .video-content {
        padding-top: 40px;
        .content-name {
          margin: 5px 0 20px;
          line-height: 1.4;
          font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 15px;
          color: rgba(0,0,0,0.6);
          letter-spacing: 0.1px;
        }
        .content-video {

        }
        .content-between {
          padding: 25px 0px;
        }
      }
      .question {
        .name {
          font-size: 17px;
          color: #444470;
          font-weight: 600;
          padding: 40px 0px 20px 0px;
        }
        p {
          font-size: 17px;
          color: #565867;
          line-height: 1.40;
          padding: 10px 0px;
        }
        .images {
          padding: 20px 0px;
        }
        .content-between {
          padding-bottom: 20px; 
        }
      }
      .buttons {
        .button {
          background-color: #444470;
          width: fit-content;
          border: 1px solid #444470;
          border-radius: 4px;
          height: 40px;
          margin: 15px 0px;
          .title {
            color: white;
            padding: 10px 20px;
            display: flex;
            font-weight: 500;
          }
        }
        .update {
          padding: 15px 0px;
          color: #444470;
        }
      }
    }
    .helpfull {
      width: 600px;
      margin-left: -56px;
      height: 100px;
      background-color: rgba(138,138,138, 0.2);
      margin-bottom: -56px;
      .title {
        font-family: inherit;
        text-align: center;
        padding: 10px;
        line-height: 1.4;
        font-size: 16px;
      }
      .emoji {
        width: 150px;
        margin: auto;
        display: flex;
        height: 50px;
        .face {
          height: 50px;
          width: 50px;
          .Bad, .Good, .Great{
            height: 30px;
            width: 30px;
            margin: auto;
            &:hover {
              height: 30px;
              width: 40px;
            }
          }
        }
      }
    }
  }
}